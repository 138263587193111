import React from "react"
import { StaticQuery, graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import Img from "gatsby-image"

const OuterWrapper = styled.section`
    ${tw`w-full m-0 p-0 bg-gray-200`}
`

const OuterContainer = styled.div`
    ${tw`container w-11/12 mx-auto px-5 py-8`}
`

const Wrapper = styled.div`
    ${tw`w-full m-0 p-0`}
`

const Container = styled.div`
    ${tw`flex flex-row items-center mx-auto px-5 py-8`}
`

const Left = styled.div`
    ${tw`w-1/2`}
`

const SectionImage = styled.div`
    ${tw`w-7/12 items-center mx-auto`}
`

const Right = styled.div`
    ${tw`w-1/2 flex items-center`}
`

const Stylediframe = styled.div`
    ${tw`block ml-auto mr-auto max-w-full`}
`

const SectionSubscribe = () => {
    return(
        <OuterWrapper>
            <OuterContainer>
                <Wrapper>
                    <Container>
                        <Left>
                            <h2>Join Pocket Shutterbug</h2>
                            <p>Join our email newsletter to receive important site updates and exclusive content.</p>
                            <SectionImage>
                                <StaticQuery
                                    query={graphql`
                                        query {
                                            join: file(relativePath: { eq: "hugo-message-sent.png" }) {
                                                childImageSharp {
                                                    fluid(maxWidth: 1216 quality: 100) {
                                                        ...GatsbyImageSharpFluid_withWebp
                                                    }
                                                }
                                            }
                                        }
                                    `}
                                    render={data => (
                                        <Img fluid={data.join.childImageSharp.fluid} alt="Join gdn" />
                                    )}
                                />
                            </SectionImage>
                        </Left>
                        <Right>
                            <Stylediframe>
                                <iframe
                                    title="SIB"
                                    width="540"
                                    height="345"
                                    src="https://59f9700a.sibforms.com/serve/MUIEAIHbe6befTWhSD4aLS4pzAatEP_v9DRpteSrcjX8JC-LaVupckB9vRz2bGQbHhu8FrOhgceNMtqTT-A_g6g3XDhC1YSpFFLYSaULmdjdoPXCO3WXmEEhd_VJ3ucTOnwVmwu_E_7CwvOrkTAQEJs6mxHH7_Xsp4wQV14KyIIPdE_NZ5i2US_bioZHCYN3DEOibv0-H2rDtU-G"
                                    frameborder="0"
                                    scrolling="auto"
                                    allowfullscreen
                                    />
                            </Stylediframe>
                        </Right>
                    </Container>
                </Wrapper>
            </OuterContainer>
        </OuterWrapper>
    )
}

export default SectionSubscribe